import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  Select,
  InputLabel,
  Chip,
  MenuItem,
  Grid
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Page from 'src/components/Page';
import * as Api from 'src/Api';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'src/utils/isEmpty';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  gutterRight: {
    marginRight: 15
  }
}));

const submitForm = async (values, enqueueSnackbar) => {
  const temp = values;
  temp.donation_amount = parseInt(parseFloat(temp.donation_amount) * 100, 10);
  const res = await Api.post('/donor', {
    ...temp
  });
  if (res?.status === 200) {
    enqueueSnackbar('Submitted Successfully', {
      autoHideDuration: 2000
    });
  } else {
    enqueueSnackbar('Failed to Submit', {
      variant: 'error',
      autoHideDuration: 2000
    });
  }
  console.log(res);
};
const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const FormSchema = Yup.object().shape({
  fname: Yup.string().required('Required'),
  lname: Yup.string().required('Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  donor_type: Yup.string().required('Required'),
  donation_amount: Yup.number().required('Required'),
  donated_to: Yup.string().required('Required')
});
export default () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [curLoan, setcurLoan] = useState({});

  // Form3 uses same data as form2
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch.form2.fetchThenUpdate();
  }, []);

  const form2Data = useSelector((state) => state.form2.data);
  const loans = isEmpty(form2Data) ? [] : form2Data;

  return (
    <Page className={classes.root} title="Register">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              fname: '',
              lname: '',
              phone: '',
              email: '',
              donor_type: '',
              donation_amount: '',
              donation_date: new Date().toISOString(),
              donated_to: []
            }}
            validationSchema={FormSchema}
            onSubmit={(values) => {
              submitForm(values, enqueueSnackbar);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              touched,
              values
            }) => {
              const chipClick = (which) => {
                if (which === 'full') {
                  setFieldValue('installment_amount', curLoan.loan_amount);
                } else if (which === 'week') {
                  setFieldValue(
                    'installment_amount',
                    curLoan.loan_amount / curLoan.term_length
                  );
                }
              };

              return (
                <Form onSubmit={handleSubmit} noValidate>
                  <Box>
                    <Typography color="textPrimary" variant="h2">
                      New Donor
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Enter donor information
                    </Typography>
                  </Box>

                  <TextField
                    label="First Name"
                    id="fname"
                    name="fname"
                    margin="normal"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fname}
                    error={Boolean(touched.fname && errors.fname)}
                    helperText={
                      touched.fname && errors.fname ? errors.fname : ''
                    }
                  />
                  <TextField
                    label="Last Name"
                    id="lname"
                    name="lname"
                    margin="normal"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lname}
                    error={Boolean(touched.lname && errors.lname)}
                    helperText={
                      touched.lname && errors.lname ? errors.lname : ''
                    }
                  />
                  <TextField
                    label="Email Address"
                    id="email"
                    name="email"
                    margin="normal"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    helperText={
                      touched.email && errors.email ? errors.email : ''
                    }
                    type="email"
                  />
                  <NumberFormat
                    customInput={TextField}
                    fullWidth
                    format="+1-(###)-###-####"
                    mask="_"
                    margin="normal"
                    label="Phone Number"
                    name="phone"
                    onBlur={handleBlur}
                    onValueChange={(valueObj) => {
                      const { value } = valueObj;
                      setFieldValue('phone', value);
                    }}
                    value={values.phone}
                    thousandSeparator
                    prefix="UGX  "
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={
                      touched.phone && errors.phone ? errors.phone : ''
                    }
                  />
                  <TextField
                    label="Donor Type"
                    id="donor_type"
                    name="donor_type"
                    margin="normal"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.donor_type}
                    select
                    error={Boolean(touched.donor_type && errors.donor_type)}
                    helperText={
                      touched.donor_type && errors.donor_type
                        ? errors.donor_type
                        : ''
                    }
                  >
                    <MenuItem value="Donor">Donor</MenuItem>
                    <MenuItem value="Investor">Investor</MenuItem>
                  </TextField>

                  <NumberFormat
                    customInput={TextField}
                    fullWidth
                    margin="normal"
                    label="Donation Amount"
                    name="donation_amount"
                    onBlur={handleBlur}
                    onValueChange={(valueObj) => {
                      const { value } = valueObj;
                      setFieldValue('donation_amount', value);
                    }}
                    value={values.donation_amount}
                    thousandSeparator
                    prefix="$  "
                    error={Boolean(
                      touched.donation_amount && errors.donation_amount
                    )}
                    helperText={
                      touched.donation_amount && errors.donation_amount
                        ? errors.donation_amount
                        : ''
                    }
                  />

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      id="donation_date"
                      name="donation_date"
                      label="Donation Date"
                      format="MM/dd/yyyy"
                      value={values.donation_date}
                      onChange={(val) => setFieldValue('donation_date', val)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  <Autocomplete
                    multiple
                    id="search"
                    options={loans}
                    getOptionLabel={(option) => `${option.fname} ${option.lname} - ${option.bike_reg_no}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Donated To: "
                        name="donated_to"
                        error={Boolean(touched.donated_to && errors.donated_to)}
                        helperText={
                          touched.donated_to && errors.donated_to
                            ? errors.donated_to
                            : ''
                        }
                      />
                    )}
                    renderOption={(option) => (
                      // TODO: Make Name Float Left
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography>
                            {`${option.fname}  ${option.lname}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{option.bike_reg_no}</Typography>
                        </Grid>
                      </Grid>
                    )}
                    onChange={(_, value) => {
                      setFieldValue('donated_to', value);
                      setcurLoan(value);
                    }}
                  />

                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};
