import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'src/utils/isEmpty';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { add, format, isValid } from 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import Page from 'src/components/Page';
import * as Api from 'src/Api';
import NumberFormat from 'react-number-format';
import SignatureCanvas from 'react-signature-canvas';
import { useSnackbar } from 'notistack';
import LocationSelect from 'src/views/forms/LocationSelect';
import {
  UGXInput,
  GroupInput,
  EndDateInput,
  EstimatedLoan,
  LoanAmount
} from './FormComponents';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{6})(?: *x(\d+))?\s*$/;

const FormSchema = Yup.object().shape({
  fname: Yup.string().required('Required'),
  lname: Yup.string().required('Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Required'),
  email: Yup.string().email('Invalid email'),
  bike_reg_no: Yup.string().required('Required'),
  loan_amount: Yup.number().required('Required'),
  term_length: Yup.number()
    .min(1, 'Should be greater than or equal to 1')
    .required('Required'),
  start_date: Yup.date().required('Required'),
  down_payment: Yup.number().required('Required')
  // .when(
  //   'loan_amount',
  //   (loan_amount, yup) => loan_amount
  //     && yup.max(loan_amount, 'Down payment cannot be greater than loan amount')
  // )
});
const RegisterView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loandata = useLocation();
  const [calcdata, setCalcData] = React.useState([]);
  const [termlen, setTermLen] = React.useState('');
  const [downpay, setDownPay] = React.useState('');
  const [loanamount, setLoanAmt] = React.useState('');
  const [weeklypay, setWeeklyPay] = React.useState('');
  const [locname, setLocName] = React.useState('');
  const [locid, setLocid] = React.useState(1);

  const [bike_price, setBikePrice] = React.useState('');
  const [desired_profit, setDesiredProfit] = React.useState('');
  const [curLoc, setCurLoc] = useState({});

  useEffect(() => {
    dispatch.location.fetchThenUpdate();
  }, []);

  useEffect(() => {
    if (loandata.state) {
      setCalcData(loandata.state); // result: 'some_value'
      setBikePrice(loandata.state.bike_price);
      setDesiredProfit(loandata.state.desiredpro);
      setTermLen(loandata.state.term_length);
      setDownPay(loandata.state.down_payment);
      setWeeklyPay(loandata.state.estimated_weekly_payment);
      setLocName(loandata.state.loc_name);
      setLocid(loandata.state.loc_id);
      const term1 = Number(loandata.state.term_length);
      const dpro = Number(loandata.state.desiredpro);
      const loanamt = Number(dpro * term1 + Number(loandata.state.bike_price));
      setLoanAmt(loanamt);
    }
  }, [loandata]);

  useEffect(() => {
    if (!loandata.state) setLocid(0);
  }, [loandata]);

  const handleLocChange = (event) => {
    setCurLoc(event.target.value);
    setLocid(event.target.value);
    async function fetchData() {
      const res = await Api.get(`/location/${event.target.value}`);
      // console.log(res);
      if (res?.data?.data && !isEmpty(res.data.data)) {
        setBikePrice(res.data.data.bike_price / 100);
        setDesiredProfit(res.data.data.desired_profit / 100);
        setLocName(res.data.data.loc_name);
      }
    }
    if (event.target.value !== '') fetchData();
  };

  const sendToBackend = async (temp, navigate, enqueueSnackbar) => {
    const res = await Api.post('/loan', {
      ...temp
    });
    console.log(res);
    if (res?.status === 200) {
      enqueueSnackbar('Registered Successfully', {
        autoHideDuration: 5000
      });
      navigate(`/admin/loanDetails/${res.data.data.loan_id}`, {
        replace: true
      });
    } else {
      enqueueSnackbar('Failed to Register', {
        variant: 'error',
        autoHideDuration: 5000
      });
    }
  };

  const submitForm = async (
    values,
    fileRef,
    signatureRef,
    navigate,
    enqueueSnackbar
  ) => {
    console.log(locname);
    const temp = { ...values, location: locname, fellowship_group: 'Group 1' };
    delete temp.loc_id;
    delete temp.desired_profit;
    const loanamt = Number(
      desired_profit * temp.term_length + Number(temp.bike_price)
    );
    temp.loan_amount = loanamt;
    // temp.desired_profit = desired_profit;
    const bkpr1 = Number(temp.bike_price);
    const dpro1 = Number(desired_profit);
    const term1 = Number(temp.term_length);
    const dpay1 = Number(temp.down_payment);
    const weeklypay1 = Math.round((bkpr1 + (dpro1 * term1 - dpay1)) / term1);
    console.log(bkpr1, dpro1, term1, dpay1, weeklypay);
    temp.weekly_payment = weeklypay1;
    console.log(JSON.stringify(temp));

    delete temp.bike_price;

    temp.loan_amount = parseInt(parseFloat(temp.loan_amount) * 100, 10);
    temp.down_payment = parseInt(parseFloat(temp.down_payment) * 100, 10);
    temp.weekly_payment = parseInt(parseFloat(temp.weekly_payment) * 100, 10);
    temp.end_date = add(temp.start_date, {
      weeks: temp.term_length || 0
    });
    if (signatureRef?.current?.isEmpty()) {
      temp.signature = '';
    } else {
      temp.signature = signatureRef.current.toDataURL('image/png');
    }
    if (!fileRef?.current?.files[0]) {
      temp.borrower_pict = '';
      sendToBackend(temp, navigate, enqueueSnackbar);
    } else {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        async (data) => {
          temp.borrower_pict = data.target.result;
          sendToBackend(temp, navigate, enqueueSnackbar);
        },
        false
      );
      reader.readAsDataURL(fileRef.current.files[0]);
    }
  };

  const fileRef = React.createRef();
  const signatureRef = React.createRef();
  const navigate = useNavigate();
  const [fileName, setFileName] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const dashboardData = useSelector((state) => state.location.data);
  const rows = dashboardData || { location: [] };
  return (
    <Page className={classes.root} title="Register">
      <Box
        display="flex"
        flexDirection="column"
        // height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            validationSchema={FormSchema}
            enableReinitialize
            initialValues={{
              fname: '',
              lname: '',
              phone: '',
              email: '',
              loc_id: locid || 0,
              bike_reg_no: '',
              bike_price: bike_price || 0,
              desired_profit: desired_profit || 0,
              loan_amount: loanamount || 1000,
              weekly_payment: weeklypay || 0,
              term_length: termlen || '',
              start_date: new Date(),
              down_payment: downpay || 100
              //  fellowship_group: 'Group 1'
            }}
            onSubmit={(values) => submitForm(
                values,
                fileRef,
                signatureRef,
                navigate,
                enqueueSnackbar
              )}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              submitCount
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Box>
                  <Typography color="textPrimary" variant="h2">
                    New Loan
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Enter Borrower and Bike Information
                  </Typography>
                </Box>

                <LocationSelect
                  name="loc_id"
                  rows={rows.location}
                  onBlur={handleBlur}
                  onChange={handleLocChange}
                  value={values.loc_id}
                />

                <TextField
                  error={Boolean(touched.fname && errors.fname)}
                  fullWidth
                  helperText={touched.fname && errors.fname ? errors.fname : ''}
                  label="First name"
                  name="fname"
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fname}
                />
                <TextField
                  error={Boolean(touched.lname && errors.lname)}
                  fullWidth
                  helperText={touched.lname && errors.lname}
                  label="Last name"
                  name="lname"
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lname}
                />
                {/* <LocationSelect
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.location}
                /> */}

                <TextField
                  error={Boolean(touched.bike_reg_no && errors.bike_reg_no)}
                  fullWidth
                  helperText={
                    touched.bike_reg_no && errors.bike_reg_no
                      ? errors.bike_reg_no
                      : ''
                  }
                  label="Bike Registration"
                  margin="normal"
                  name="bike_reg_no"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.bike_reg_no}
                />

                <TextField
                  name="bike_price"
                  value={values.bike_price}
                  type="hidden"
                />

                <TextField
                  name="desired_profit"
                  value={values.desired_profit}
                  type="hidden"
                />

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    margin="normal"
                    id="start_date"
                    name="start_date"
                    label="Start Date"
                    format="MM/dd/yyyy"
                    value={values.start_date}
                    onChange={(val) => setFieldValue('start_date', val)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>

                <TextField
                  fullWidth
                  label="Term Length (weeks)"
                  error={Boolean(touched.term_length && errors.term_length)}
                  helperText={
                    touched.term_length && errors.term_length
                      ? errors.term_length
                      : ''
                  }
                  min={0}
                  type="number"
                  margin="normal"
                  name="term_length"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.term_length}
                />
                <EndDateInput
                  label="End Date"
                  name="end_date"
                  values={values}
                />

                {/* <UGXInput
                  label="Loan Amount"
                  name="loan_amount"
                  onBlur={handleBlur}
                  error={Boolean(touched.loan_amount && errors.loan_amount)}
                  helperText={
                    touched.loan_amount && errors.loan_amount
                      ? errors.loan_amount
                      : ''
                  }
                  onValueChange={(valueObj) => {
                    const { value } = valueObj;
                    setFieldValue('loan_amount', value);
                  }}
                  value={values.loan_amount}
                /> */}

                <LoanAmount
                  label="Loan Amount"
                  name="loan_amount"
                  values={values}
                  value={values.loan_amount}
                  fullWidth
                  margin="normal"
                  thousandSeparator
                  prefix="UGX  "
                />

                <UGXInput
                  label="Down Payment"
                  name="down_payment"
                  onBlur={handleBlur}
                  onValueChange={(valueObj) => {
                    const { value } = valueObj;
                    setFieldValue('down_payment', value);
                  }}
                  value={values.down_payment}
                  thousandSeparator
                  prefix="UGX  "
                  error={Boolean(touched.down_payment && errors.down_payment)}
                  helperText={
                    touched.down_payment && errors.down_payment
                      ? errors.down_payment
                      : ''
                  }
                />

                <EstimatedLoan
                  label="Weekly Payment"
                  name="weekly_payment"
                  values={values}
                  profit={desired_profit}
                  fullWidth
                  margin="normal"
                  thousandSeparator
                  prefix="UGX  "
                />

                {/* <UGXInput
                  label="Down Payment"
                  name="down_payment"
                  onBlur={handleBlur}
                  onValueChange={(valueObj) => {
                    const { value } = valueObj;
                    setFieldValue('down_payment', value);
                  }}
                  value={values.down_payment}
                  thousandSeparator
                  prefix="UGX  "
                  error={Boolean(touched.down_payment && errors.down_payment)}
                  helperText={
                    touched.down_payment && errors.down_payment
                      ? errors.down_payment
                      : ''
                  }
                /> */}

                {/* <GroupInput
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fellowship_group}
                  type="hidden"
                /> */}

                <TextField
                  label="Email Address"
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email ? errors.email : ''}
                  type="email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                />
                <NumberFormat
                  customInput={TextField}
                  fullWidth
                  format="+256-###-######"
                  mask="_"
                  margin="normal"
                  label="Phone Number"
                  name="phone"
                  onBlur={handleBlur}
                  onValueChange={(valueObj) => {
                    const { value } = valueObj;
                    setFieldValue('phone', value);
                  }}
                  value={values.phone}
                  thousandSeparator
                  prefix="UGX  "
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone ? errors.phone : ''}
                />
                <Grid container item xs={12} alignItems="center">
                  <Grid item xs={3}>
                    <Button variant="contained" component="label" size="small">
                      Upload Image
                      <input
                        type="file"
                        hidden
                        ref={fileRef}
                        onChange={() => {
                          setFileName(fileRef.current?.files[0]?.name);
                        }}
                      />
                    </Button>
                    {/* {!fileName && submitCount > 0 && (
                      <FormHelperText id="my-helper-text" error>
                        Required
                      </FormHelperText>
                    )} */}
                  </Grid>
                  <Grid item xs={9}>
                    <Typography noWrap>{fileName}</Typography>
                  </Grid>
                </Grid>
                <div style={{ border: '1px solid', marginTop: '10px' }}>
                  <SignatureCanvas
                    ref={signatureRef}
                    penColor="green"
                    canvasProps={{
                      width: 500,
                      height: 200,
                      className: 'sigCanvas'
                    }}
                  />
                </div>
                <Button
                  variant="contained"
                  component="label"
                  size="small"
                  onClick={() => {
                    signatureRef.current.clear();
                  }}
                >
                  Clear
                </Button>
                {/* {submitCount > 0 && signatureRef?.current?.isEmpty() && (
                  <FormHelperText id="my-helper-text" error>
                    Required
                  </FormHelperText>
                )} */}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default RegisterView;
