import React, { useState } from 'react';
import {
 Box, Container, makeStyles, Typography 
} from '@material-ui/core';
import Page from 'src/components/Page';
import LoanInfoTable from '../../dashboard/LoanInfoTable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    '& div.rdg': {
      fontFamily: theme.typography.fontFamily
    }
  }
}));

const CustomerListView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Box>
          <Typography color="textPrimary" variant="h2">
            Loan Info
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            View all loans and their Associated Donors
          </Typography>
        </Box>
        <Box mt={3}>
          <LoanInfoTable />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
