import React, { useEffect, useState, forwardRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from '@material-table/core';
import { ExportCsv } from '@material-table/exporters';
import TableIcons from 'src/utils/TableIcons';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Grid } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { CSVLink } from 'react-csv';
import { ugF, tdF, liF } from './RowFormatters';
import { MuiTableEditRow, FormikMTInput } from './RowValidator';

const FormSchema = Yup.object().shape({
  bike_reg_no: Yup.string().required('Required'),
  loan_amount: Yup.number().required('Required'),
  term_length: Yup.number()
    .min(1, 'Should be greater than or equal to 1')
    .required('Required'),
  start_date: Yup.date().required('Required'),
  down_payment: Yup.number()
    .required('Required')
    .when(
      'loan_amount',
      (loan_amount, yup) => loan_amount
        && yup.max(loan_amount, 'Down payment cannot be greater than loan amount')
    )
});

const columns = [
  { field: 'bike_reg_no', title: 'Bike', render: liF('bike_reg_no') },
  { field: 'name', title: 'Name' },
  {
    field: 'loan_amount',
    title: 'Loan Amount',
    render: ugF('loan_amount')
  },
  {
    field: 'down_payment',
    title: 'Down Payment',
    render: ugF('down_payment')
  },
  {
    field: 'amount_remaining',
    title: 'Amount Remaining',
    render: ugF('amount_remaining'),
    editable: 'never'
  },
  {
    field: 'fellowship_group',
    title: 'Group'
  },
  {
    field: 'location',
    title: 'Location'
  },
  {
    field: 'start_date',
    title: 'Start Date',
    render: tdF('start_date'),
    editable: 'never'
  },
  {
    field: 'term_length',
    title: 'Term Length'
  },
  {
    field: 'end_date',
    title: 'End Date',
    render: tdF('end_date')
  },
  {
    field: 'donor_info',
    title: 'Donors',
    render: (row) => {
      const info = row.donor_info || [];
      return <div>{info.map((el) => el.name).join(', ')}</div>;
    },
    editable: 'never'
  }
];

export default React.memo(({ rows = [] }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // used for optimistic update of Table Data
  const [data, setData] = useState(rows);
  useEffect(() => {
    setData(rows);
  }, [rows]);

  const cleanFormData = (newData) => {
    const name = newData.name.split(' ');
    const params = {
      loan_id: newData.loan_id,
      fname: name[0],
      phone: newData.phone,
      email: newData.email,
      lname: name[1],
      location: newData.location,
      bike_reg_no: newData.bike_reg_no,
      loan_amount: newData.loan_amount,
      term_length: parseInt(parseFloat(newData.term_length), 10),
      start_date: newData.start_date,
      end_date: newData.end_date,
      down_payment: newData.down_payment,
      fellowship_group: newData.fellowship_group
    };

    return params;
  };

  const updateLoan = async (cleanedData) => {
    const res = await dispatch.dashboard.updateLoanInfo(cleanedData);
    if (res?.status === 200) {
      enqueueSnackbar('Upadate Successful', {
        autoHideDuration: 2000
      });
    } else {
      enqueueSnackbar('Failed to Update', {
        variant: 'error',
        autoHideDuration: 2000
      });
    }
    // window.location.reload();
  };

  const deleteLoan = async (oldData) => {
    if (!oldData.loan_id) {
      console.error('No Loan Id when deleting');
    } else {
      const { loan_id } = oldData;
      const res = await dispatch.dashboard.deleteLoanInfo(loan_id);
      if (res?.status === 200) {
        enqueueSnackbar('Delete Successfully', {
          autoHideDuration: 2000
        });
      } else {
        enqueueSnackbar('Failed to Delete', {
          variant: 'error',
          autoHideDuration: 2000
        });
      }
      // window.location.reload();
    }
  };

  const operation = (oldData, newData) => new Promise((resolve, reject) => {
      const dataUpdate = [...data];

      const index = oldData.tableData.id;
      if (newData) {
        const cleanedData = cleanFormData(newData);

        dataUpdate[index] = { ...newData };
        updateLoan(cleanedData);
      } else {
        dataUpdate.splice(index, 1);
        deleteLoan(oldData);
      }
      setData([...dataUpdate]);
      resolve();
    });

  return (
    <Grid className="tableMainDiv">
      <CSVLink data={data}>Export All</CSVLink>
      <MaterialTable
        title="Loans"
        icons={{
          ...TableIcons
          // Edit: forwardRef((props, ref) => (
          //   <TableIcons.Edit {...props} fontSize="small" ref={ref} />
          // )),
          // Delete: forwardRef((props, ref) => (
          //   <TableIcons.Delete {...props} fontSize="small" ref={ref} />
          // ))
        }}
        columns={columns}
        data={data}
        options={{
          rowStyle: {
            fontFamily: theme.typography.fontFamily,
            ...theme.typography.body2
          },
          exportMenu: [
            {
              label: 'Export CSV',
              exportFunc: (cols, datas) => {
                const cleaned = datas.map((i) => i.map((j) => (typeof j === 'object' ? JSON.stringify(j) : j)));

                return ExportCsv(cols, cleaned, 'LoanInfoTable');
              }
            }
          ]
        }}
        editable={{
          onRowUpdate: (newData, oldData) => operation(oldData, newData),
          onRowDelete: (oldData) => operation(oldData)
        }}
        components={{
          EditRow: ({ onEditingApproved, ...props }) => {
            return MuiTableEditRow({ onEditingApproved, ...props }, FormSchema);
          },
          EditField: FormikMTInput,
          Action: (props) => {
            return <MTableAction {...props} size="small" />;
          }
        }}
      />
    </Grid>
  );
});
