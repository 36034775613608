import React, { useState, useEffect } from 'react';
import { useNavigate, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { add, format, isValid } from 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import Page from 'src/components/Page';
import * as Api from 'src/Api';
import NumberFormat from 'react-number-format';
import SignatureCanvas from 'react-signature-canvas';
import { useSnackbar } from 'notistack';

import {
  LocationSelect,
  UGXInput,
  GroupInput,
  EndDateInput
} from './FormComponents';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const sendToBackend = async (temp, navigate, enqueueSnackbar) => {
  const res = await Api.post('/location', {
    ...temp
  });
  console.log(res);
  if (res?.status === 200) {
    // enqueueSnackbar('Location Added Successfully', {
    //   autoHideDuration: 5000
    // });
    navigate('/admin/location', {
      replace: false
    });
  } else {
    enqueueSnackbar('Failed to Add Location', {
      variant: 'error',
      autoHideDuration: 5000
    });
  }
};

const submitForm = async (
  values,
  fileRef,
  signatureRef,
  navigate,
  enqueueSnackbar
) => {
  const temp = { ...values };
  console.log(temp);
  temp.bike_price = parseInt(parseFloat(temp.bike_price) * 100, 10);

  let profit = parseInt(parseFloat(Number(temp.desired_profit)) * 100, 10);
  if (temp.desired_profit.includes('%')) {
    const value = temp.desired_profit.replace('%', '');
    profit = temp.bike_price * (Number(value) / 100);
  } else {
    profit = parseInt(parseFloat(Number(temp.desired_profit)) * 100, 10);
  }
  temp.desired_profit = profit;
  sendToBackend(temp, navigate, enqueueSnackbar);
};

const FormSchema = Yup.object().shape({
  loc_name: Yup.string().required('Required'),
  bike_price: Yup.number().required('Required'),
  desired_profit: Yup.string().required('Required')
});
const AddLocation = () => {
  // const history = useHistory();
  const classes = useStyles();
  const fileRef = React.createRef();
  const signatureRef = React.createRef();
  const navigate = useNavigate();
  const [fileName, setFileName] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Page className={classes.root} title="Add Location">
      <Box
        display="flex"
        flexDirection="column"
        // height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            validationSchema={FormSchema}
            initialValues={{
              loc_name: '',
              bike_price: 0,
              desired_profit: ''
            }}
            onSubmit={(values) => submitForm(
              values,
              fileRef,
              signatureRef,
              navigate,
              enqueueSnackbar
            )}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              submitCount
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Box>
                  <Typography color="textPrimary" variant="h2">
                    New Location
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Enter Location and Bike Information
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.loc_name && errors.loc_name)}
                  fullWidth
                  helperText={
                    touched.loc_name && errors.loc_name ? errors.loc_name : ''
                  }
                  label="Location Name"
                  name="loc_name"
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.loc_name}
                />
                <UGXInput
                  label="Bike Price"
                  name="bike_price"
                  onBlur={handleBlur}
                  error={Boolean(touched.bike_price && errors.bike_price)}
                  helperText={
                    touched.bike_price && errors.bike_price
                      ? errors.bike_price
                      : ''
                  }
                  onValueChange={(valueObj) => {
                    const { value } = valueObj;
                    setFieldValue('bike_price', value);
                  }}
                  value={values.bike_price}
                />
                {/* <NumberFormat
                                    customInput={TextField}
                                    thousandSeparator
                                    error={Boolean(touched.desired_profit && errors.desired_profit)}
                                    fullWidth
                                    helperText={touched.desired_profit && errors.desired_profit}
                                    label="Weekly Desired Profit (Enter % or fixed price, 
                                        weekly profit amount desired)"
                                    name="desired_profit"
                                    margin="normal"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.desired_profit}
                                /> */}

                <TextField
                  fullWidth
                  name="desired_profit"
                  label="Weekly Desired Profit"
                  placeholder="(Enter % or fixed price, 
                                        weekly profit amount desired)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">UGX</InputAdornment>,
                  }}
                  error={Boolean(touched.desired_profit && errors.desired_profit)}
                  helperText={
                    touched.desired_profit && errors.desired_profit
                      ? errors.desired_profit
                      : ''
                  }
                  value={values.desired_profit}
                />

                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default AddLocation;
