import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function LogoutView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem('authStatus', '');
    dispatch.auth.logout();
    navigate('/');
  }, []);
  return <>Successfully Logged Out</>;
}
