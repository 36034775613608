import React from 'react';
import { MTableEditField, MTableEditRow } from '@material-table/core';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import { Field, Formik, getIn } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import NumberFormat from 'react-number-format';
import { convertToCents } from 'src/utils/formatCurrency';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  GroupInput,
  LocationSelect,
  UGXInput,
  USDInput,
  EndDateInput
} from '../forms/FormComponents';

export const MuiTableEditRow = ({ onEditingApproved, ...props }, schema) => {
  const initialValues = { ...props.data };
  initialValues.bike_price /= 100;
  initialValues.desired_profit /= 100;

  return (
    <Formik
      validationSchema={schema} // included a validation object
      initialValues={initialValues}
      onSubmit={(values) => {
        const cleanedValues = convertToCents(values);
        //  alert(JSON.stringify(cleanedValues));
        onEditingApproved(props.mode, cleanedValues, props.data);
      }}
    >
      {({ submitForm }) => (
        <MTableEditRow {...props} onEditingApproved={submitForm} />
      )}
    </Formik>
  );
};

export const FormikMTInput = (props) => (
  <Field name={props.columnDef.field}>
    {({ field, form }) => {
      const { name } = field;
      const {
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
        submitCount
      } = form;

      if (name === 'bike_price') {
        return (
          <UGXInput
            name={String(name)}
            onBlur={handleBlur}
            error={Boolean(touched.bike_price && errors.bike_price)}
            helperText={
              touched.bike_price && errors.bike_price ? errors.bike_price : ''
            }
            onValueChange={(valueObj) => {
              const { value } = valueObj;
              setFieldValue('bike_price', value);
            }}
            value={values.bike_price}
          />
        );
      }

      if (name === 'loc_name') {
        return (
          <TextField
            label="Location Name"
            id="loc_name"
            name="loc_name"
            margin="normal"
            fullWidth
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.loc_name}
            style={{ marginTop: '-8px' }}
            error={Boolean(touched.loc_name && errors.loc_name)}
            helperText={
              touched.loc_name && errors.loc_name ? errors.loc_name : ''
            }
          />
        );
      }

      if (name === 'desired_profit') {
        return (
          <TextField
            name={String(name)}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">UGX</InputAdornment>
              )
            }}
            error={Boolean(touched.desired_profit && errors.desired_profit)}
            helperText={
              touched.desired_profit && errors.desired_profit
                ? errors.desired_profit
                : ''
            }
            onValueChange={(valueObj) => {
              const { value } = valueObj;
              setFieldValue('desired_profit', value);
            }}
            value={values.desired_profit}
          />
        );
      }

      return (
        <div>
          <TextField
            error={Boolean(touched[name] && errors[name])}
            fullWidth
            helperText={touched[name] && errors[name] ? errors[name] : ''}
            name={String(name)}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values[name]}
          />
        </div>
      );
    }}
  </Field>
);
