import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import LoanInfoTable from './LoanInfoTable';
import DonorInfoTable from './DonorInfoTable';
import '../../components/assets/Style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DashboardView = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch.dashboard.fetchThenUpdate();
  }, []);

  const dashboardData = useSelector((state) => state.dashboard.data);
  const rows = dashboardData || { loan: [], donor: [] };

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        {/* <Button
          onClick={() => {
            dispatch.dashboard.fetchThenUpdate();
          }}
        >
          Reload
        </Button> */}
        <Box>
          <Typography color="textPrimary" variant="h2">
            Loan Info
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            View all loans and their Associated Donors
          </Typography>
        </Box>
        <Box mt={3}>
          <LoanInfoTable rows={rows.loan} />
        </Box>
        <Box mt={5}>
          <Typography color="textPrimary" variant="h2">
            Donor Info
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            View all donors and their Associated Loans
          </Typography>
        </Box>
        <Box mt={3}>
          <DonorInfoTable rows={rows.donor} />
        </Box>
      </Container>
    </Page>
  );
};

export default DashboardView;
