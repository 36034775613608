import React from 'react';

import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

export default (props) => {
  const { open, children, transitionOnAppear } = props;
  return (
    <SlideDown
      className="my-dropdown-slidedown"
      transitionOnAppear={transitionOnAppear}
    >
      {open ? children : null}
    </SlideDown>
  );
};
