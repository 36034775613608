import React, { useState, useEffect, useRef } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from '@material-table/core';
import { ExportCsv } from '@material-table/exporters';
import TableIcons from 'src/utils/TableIcons';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { CSVLink } from 'react-csv';
import { ugF } from './RowFormatters';
import { MuiTableEditRow, FormikMTInput } from './RowValidator';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 480
  }
}));
export const FormSchema = Yup.object().shape({
  loc_name: Yup.string().required('Required'),
  bike_price: Yup.number().required('Required'),
  desired_profit: Yup.string().required('Required')
});

const columns = [
  {
    field: 'loc_name',
    title: 'Location Name'
  },
  {
    field: 'bike_price',
    title: 'Bike Price',
    position: 'sticky',
    top: 0,
    render: ugF('bike_price')
  },
  {
    field: 'desired_profit',
    title: 'Weekly Desired Profit',
    position: 'sticky',
    top: 0,
    render: ugF('desired_profit')
  }
];

export default React.memo(({ rows = [] }) => {
  const history = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [data, setData] = useState(rows);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch.location.fetchThenUpdate();
    setData(rows);
  }, [rows]);
  const cleanFormData = (newData) => {
    // console.log('newdata', newData.desired_profit);
    // let profit = parseInt(parseFloat(Number(newData.desired_profit)) * 100, 10);
    // if (newData.desired_profit.includes('%')) {
    //     const value = newData.desired_profit.replace('%', '');
    //     profit = (newData.bike_price * (Number(value) / 100));
    // }
    const params = {
      loc_id: newData.loc_id,
      loc_name: newData.loc_name,
      bike_price: Number(newData.bike_price),
      desired_profit: Number(newData.desired_profit)
    };
    return params;
  };
  const updateLocation = async (cleanedData) => {
    console.log(cleanedData);
    const res = await dispatch.location.updateLocation(cleanedData);
    if (res?.status === 200) {
      enqueueSnackbar('Update Successful', {
        autoHideDuration: 2000
      });
    } else {
      enqueueSnackbar('Failed to Update', {
        variant: 'error',
        autoHideDuration: 2000
      });
    }
    // window.location.reload();
  };
  const deleteLocation = async (oldData) => {
    if (!oldData.loc_id) {
      console.error('No location Id when deleting');
    } else {
      const { loc_id } = oldData;
      const res = await dispatch.location.deleteLocation(loc_id);
      if (res?.status === 200) {
        enqueueSnackbar('Delete Successful', {
          autoHideDuration: 2000
        });
      } else {
        enqueueSnackbar('Failed to Delete', {
          variant: 'error',
          autoHideDuration: 2000
        });
      }
      // window.location.reload();
    }
  };
  const operation = (oldData, newData) => new Promise((resolve, reject) => {
    const dataUpdate = [...data];
    const index = oldData.tableData.id;
    if (newData) {
      const cleanedData = cleanFormData(newData);
      dataUpdate[index] = { ...newData };
      updateLocation(cleanedData);
    } else {
      dataUpdate.splice(index, 1);
      deleteLocation(oldData);
    }
    setData([...dataUpdate]);
    resolve();
  });

  return (
    <Grid className="tableMainDiv">
      <MaterialTable
        enableReinitialize
        title="Locations"
        icons={TableIcons}
        columns={columns}
        data={data}
        options={{
          rowStyle: {
            fontFamily: theme.typography.fontFamily,
            ...theme.typography.body2
          }
        }}
        actions={[
          {
            icon: Add,
            tooltip: 'Add User',
            isFreeAction: true,
            onClick: (event) => history('/admin/addlocation')
          }
        ]}
        editable={{
          onRowUpdate: (newData, oldData) => operation(oldData, newData),
          onRowDelete: (oldData) => operation(oldData)
        }}
        components={{
          EditRow: ({ onEditingApproved, ...props }) => {
            return MuiTableEditRow({ onEditingApproved, ...props }, FormSchema);
          },
          EditField: FormikMTInput,
          Action: (props) => {
            return <MTableAction {...props} size="small" />;
          }
        }}
      />
    </Grid>
  );
});
