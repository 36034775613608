import deepmerge from 'deepmerge';
import * as Api from 'src/Api';
import { add, format, differenceInSeconds } from 'date-fns';
import isEmpty from 'src/utils/isEmpty';

// number of seconds before refetching
const TIME_BLOCK = 120;

const form3 = {
  state: { lastFetch: new Date(), data: {} }, // initial state
  reducers: {
    update(state, payload) {
      return { lastFetch: new Date(), data: payload };
    }
  },
  effects: (dispatch) => ({
    async fetchThenUpdate(payload, rootState) {
      const sinceLastSecs = differenceInSeconds(
        new Date(),
        rootState.form3.lastFetch
      );

      if (isEmpty(rootState.form3.data) || sinceLastSecs > TIME_BLOCK) {
        console.log(
          'Fetching Form3 Data. Time since last fetch',
          sinceLastSecs
        );

        const res = await Api.get('/donor/list');
        if (res?.data?.data && res.data?.data.length) {
          const out = res.data.data;
          dispatch.form3.update(out);
        }
      }
    },
    async deleteDonateTo(payload, rootState) {
      console.log(`Deleting donation with Id ${payload}`);
      return Api.del(`/donor/donation/${payload}`);
    }
  })
};

export default form3;
