import { init } from '@rematch/core';

import dashboard from './dashboard.model';
import form2 from './form2.model';
import form3 from './form3.model';
import auth from './auth.model';
import location from './location.model';

const store = init({
  models: {
    dashboard,
    form2,
    form3,
    auth,
    location
  }
});

export default store;
