import deepmerge from 'deepmerge';
import * as Api from 'src/Api';
import { add, format, differenceInSeconds } from 'date-fns';
import isEmpty from 'src/utils/isEmpty';

// number of seconds before refetching
const TIME_BLOCK = 120;

const form2 = {
  state: { lastFetch: new Date(), data: {} }, // initial state
  reducers: {
    update(state, payload) {
      return { lastFetch: new Date(), data: payload };
    }
  },
  effects: (dispatch) => ({
    async fetchThenUpdate(payload, rootState) {
      const sinceLastSecs = differenceInSeconds(
        new Date(),
        rootState.form2.lastFetch
      );

      if (isEmpty(rootState.form2.data) || sinceLastSecs > TIME_BLOCK) {
        console.log(
          'Fetching Form2 Data. Time since last fetch',
          sinceLastSecs
        );

        const res = await Api.get('/loan');
        if (res?.data?.data && res.data.data.length) {
          const out = res.data.data;
          dispatch.form2.update(out);
        }
      }
    },
    async deletePayment(payload, rootState) {
      console.log(`Deleting Payment with Id ${payload}`);
      return Api.del(`/payment/${payload}`);
    },

    async viewPaymentInfo(payload, rootState) {
      return Api.put(`/payment/${payload}`, payload);
    },
    async updatePaymentInfo(payload, rootState) {
      return Api.put('/payment/', payload);
    }
  })
};

export default form2;
