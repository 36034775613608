import axios from 'axios';

// const useLive = process.env.NODE_ENV === 'production';
const useLive = true;
// const live_url = 'http://54.226.153.216:8000';
const live_url = 'https://donordashbackend.herokuapp.com';
const local_url = 'http://localhost:8000';
export const BASE_URL = useLive ? live_url : local_url;

export const get = async (endpoint, data) => {
  const url = BASE_URL + endpoint;
  console.log(`GET TO: ${url}`);
  return axios({
    method: 'get',
    url,
    data,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  }).catch((error) => {
    // handle error
    if (error.response) {
      console.error(error.response.data);
    }
  });
};

export const post = async (endpoint, data) => {
  const url = BASE_URL + endpoint;
  console.log(`POST TO: ${url}`);
  return axios({
    method: 'post',
    url,
    data,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  }).catch((error) => {
    // handle error
    if (error.response) {
      console.error(error.response.data);
    }
  });
};

export const put = async (endpoint, data) => {
  const url = BASE_URL + endpoint;
  console.log(`POST TO: ${url}`);
  return axios({
    method: 'put',
    url,
    data,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  }).catch((error) => {
    // handle error
    if (error.response) {
      console.error(error.response.data);
    }
  });
};

export const del = async (endpoint) => {
  const url = BASE_URL + endpoint;
  console.log(`DELETE TO: ${url}`);
  return axios({
    method: 'delete',
    url,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  }).catch((error) => {
    // handle error
    if (error.response) {
      console.error(error.response.data);
    }
  });
};
