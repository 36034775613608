import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  Chip,
  MenuItem,
  Grid
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Page from 'src/components/Page';
import SlideToggle from 'src/utils/SlideToggle';
import * as Api from 'src/Api';
import isEmpty from 'src/utils/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { useSnackbar } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { format } from 'date-fns';
// import LoanInfoText from './LoanInfoText';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  gutterRight: {
    marginRight: 15
  }
}));

const Paymentedit = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [paymentid, setpaymentid] = React.useState('');
  const [paymentamt, setpaymentamt] = React.useState('');
  const [type, settype] = React.useState('');
  const [transid, settransid] = React.useState('');
  const [paymentdate, setpaymentdate] = React.useState('');

  const formSchema = Yup.object().shape({
    payment_type: Yup.string().required('Required'),
    payment_amount: Yup.string().required('Required'),
    transaction_id: Yup.string().when('payment_type', (payment_type, yup) => {
      return payment_type && payment_type === 'Mobile'
        ? yup.required('Required')
        : yup;
    })
  });

  const [curLoan, setcurLoan] = useState({});
  const [remaining, setremaining] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch.form2.fetchThenUpdate();
  }, []);

  //   const form2Data = useSelector((state) => state.form2.data);
  //   const loans = isEmpty(form2Data) ? [] : form2Data;

  //   get remaining amount after new loan is selected.
  //   useEffect(() => {
  //     async function fetchData() {
  //       const res = await Api.get(`/payment/${id}`);
  //       if (res?.data?.data) {
  //         console.log(res.data);
  //         setremaining(res.data.data.remaining_amount);
  //       }
  //     }
  //     if (curLoan.loan_id) fetchData();
  //   }, [curLoan]);

  async function getPaymentById() {
    const res = await Api.get(`/payment/${id}`);
    if (res?.data?.data) {
      setpaymentid(res.data.data.payment_id);
      setpaymentamt(res.data.data.payment_amount);
      settype(res.data.data.payment_type);
      settransid(res.data.data.transaction_id);
      const datestore = format(
        new Date(res.data.data.payment_date),
        'MM/dd/yyyy'
      );
      setpaymentdate(datestore);
    } else {
      enqueueSnackbar('Failed to Load', {
        variant: 'error',
        autoHideDuration: 2000
      });
    }
  }

  // const operation = (oldData, newData) => new Promise((resolve, reject) => {
  //     const dataUpdate = [...data];
  //     const index = oldData.tableData.id;
  //     if (newData) {
  //       const cleanedData = cleanFormData(newData);
  //       dataUpdate[index] = { ...newData };
  //       updateDonor(cleanedData);
  //     } else {
  //       dataUpdate.splice(index, 1);
  //       deleteDonor(oldData);
  //     }
  //     setData([...dataUpdate]);
  //     resolve();
  //   });

  const updatePayment = async (values) => {
    // const temp = values;
    const temp = { ...values, payment_id: parseInt(values.payment_id, 10) };
    const paymetam = temp.payment_amount.split(' ');
    const newpaymtamt = paymetam[2].replace(',', '');
    const newtemp = { ...values, payment_amount: parseInt(newpaymtamt, 10) };
    const res = await Api.put('/payment/updatePayment', {
      ...newtemp
    });
    if (res?.status === 200) {
      enqueueSnackbar('Upadate Successful', {
        autoHideDuration: 2000
      });
    } else {
      enqueueSnackbar('Failed to Update', {
        variant: 'error',
        autoHideDuration: 2000
      });
    }
    // window.location.reload();
  };

  useEffect(() => {
    getPaymentById();
  }, []);

  return (
    <Page className={classes.root} title="Register">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            enableReinitialize
            initialValues={{
              payment_amount: paymentamt || '',
              payment_id: paymentid || '',
              payment_type: type || '',
              payment_date: new Date().toISOString()
            }}
            // validationSchema={formSchema}
            onSubmit={(values) => {
              updatePayment(values, enqueueSnackbar);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              touched,
              values
            }) => {
              return (
                <Form onSubmit={handleSubmit} noValidate>
                  <Box>
                    <Typography color="textPrimary" variant="h2">
                      Edit Payments
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Enter loan payment information
                    </Typography>
                  </Box>
                  <Grid container alignItems="baseline">
                    <NumberFormat
                      customInput={TextField}
                      error={Boolean(touched.payment_id && errors.payment_id)}
                      helperText={
                        touched.payment_id && errors.payment_id
                          ? errors.payment_id
                          : ''
                      }
                      margin="normal"
                      label="Payment Id"
                      name="payment_id"
                      id="payment_id"
                      disabled
                      onBlur={handleBlur}
                      onChangeCapture={(e) => setpaymentid(e.target.value)}
                      value={paymentid}
                      className={classes.gutterRight}
                      thousandSeparator
                    />
                  </Grid>
                  <Grid container alignItems="baseline">
                    <NumberFormat
                      customInput={TextField}
                      margin="normal"
                      label="Payment Amount"
                      name="payment_amount"
                      id="payment_amount"
                      onBlur={handleBlur}
                      onChangeCapture={(e) => setpaymentamt(e.target.value)}
                      value={paymentamt}
                      className={classes.gutterRight}
                      thousandSeparator
                      prefix="UGX  "
                    />
                  </Grid>
                  <TextField
                    label="Payment Type"
                    id="payment_type"
                    name="payment_type"
                    margin="normal"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={type}
                  >
                    <MenuItem value="Cash">Cash</MenuItem>
                    <MenuItem value="Mobile">Mobile</MenuItem>
                  </TextField>
                  <SlideToggle open={values.payment_type === 'Mobile'}>
                    <TextField
                      fullWidth
                      label="Transaction ID"
                      name="transaction_id"
                      margin="normal"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={transid}
                      helperText={
                        touched.transid && errors.transid ? 'Required' : ''
                      }
                      error={Boolean(touched.transid && errors.transid)}
                    />
                  </SlideToggle>
                  <Grid container alignItems="baseline">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        id="payment_date"
                        name="payment_date"
                        //   label="Payment Date"
                        format="MM/dd/yyyy"
                        value={paymentdate}
                        onChange={(val) => setFieldValue('payment_date', val)}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Update
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default Paymentedit;
