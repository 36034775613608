import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';

export default function InteractiveList(props) {
  const history = useNavigate();
  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="right">Payment ID</TableCell>
              <TableCell align="right">PAYMENT AMOUNT</TableCell>
              <TableCell align="right">PAYMENT TYPE</TableCell>
              <TableCell align="right">Transaction ID</TableCell>
              <TableCell align="center">PAYMENT DATE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.paymentData.map((row) => (
              <TableRow key={row.payment_id}>
                <TableCell>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      props.deletePayment(row.payment_id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    aria-label="edit"
                    onClick={() => history(`/admin/payment-edit/${row.payment_id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">{row.payment_id}</TableCell>
                <TableCell component="th" scope="row">
                  <NumberFormat
                    thousandSeparator
                    value={row.payment_amount / 100}
                    displayType="text"
                  />
                </TableCell>

                <TableCell align="center">{row.payment_type}</TableCell>
                <TableCell align="center">{row.transaction_id}</TableCell>
                <TableCell align="center">
                  {row.payment_date?.split('T')[0]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
