import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'src/utils/isEmpty';
import NavBar from './NavBar/index2';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const authenticate = async (path, authStatus, navigate) => {
  const localLogin = localStorage.getItem('authStatus');
  if (!authStatus && localLogin) {
    authStatus = localLogin;
  }
  console.log(
    'Authenticating. Route [',
    path.pathname,
    '] Authstatus [',
    authStatus,
    ']'
  );
  const { pathname = '' } = path;
  if (isEmpty(authStatus)) {
    console.log('Not Authenticated. Moving to login');
    navigate('/login');
  } else if (!pathname.includes(authStatus)) {
    console.log('Not Enough Credentials. Moving to login');
    navigate('/login');
  }
};

const DashboardLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const path = useLocation();
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.auth.authStatus);
  const navigate = useNavigate();
  useEffect(() => {
    const localLogin = localStorage.getItem('authStatus');
    if (!authStatus && localLogin) {
      console.log(`Logging In From Local Storage ${localLogin}`);
      dispatch.auth.login(localLogin);
    }
  }, []);
  useEffect(() => {
    authenticate(path, authStatus, navigate);
  }, [authStatus]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
