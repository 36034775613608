import deepmerge from 'deepmerge';
import * as Api from 'src/Api';
import { add, format, differenceInSeconds } from 'date-fns';
import isEmpty from 'src/utils/isEmpty';

const auth = {
  state: { authStatus: 'admin' }, // authStatus is either admin or user or blank for no login
  reducers: {
    login(state, payload) {
      // payload should be admin or user
      return { authStatus: payload };
    },
    logout(state, payload) {
      return { authStatus: '' };
    }
  }
};

export default auth;
