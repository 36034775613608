import React, { useState, useEffect, useRef } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from '@material-table/core';
import { ExportCsv } from '@material-table/exporters';
import TableIcons from 'src/utils/TableIcons';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { CSVLink } from 'react-csv';
import { usF, tdF, diF } from './RowFormatters';
import { MuiTableEditRow, FormikMTInput } from './RowValidator';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 480
  }
}));
export const FormSchema = Yup.object().shape({
  fname: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  donation_amount: Yup.string().required('Required'),
  email: Yup.string()
    .email()
    .required('Required')
});

const columns = [
  {
    field: 'fname',
    title: 'First Name',
    position: 'sticky',
    top: 0,
    render: diF('fname')
  },
  {
    field: 'lname',
    title: 'Last Name',
    position: 'sticky',
    top: 0
  },
  {
    field: 'phone',
    title: 'Phone',
    position: 'sticky',
    top: 0
  },
  {
    field: 'email',
    title: 'Email',
    position: 'sticky',
    top: 0
  },
  {
    field: 'donor_type',
    title: 'Donor Type',
    position: 'sticky',
    top: 0
  },
  {
    field: 'donation_amount',
    title: 'Donation Amount',
    position: 'sticky',
    top: 0,
    render: usF('donation_amount')
  },
  {
    field: 'donation_date',
    title: 'Donation Date',
    position: 'sticky',
    top: 0,
    render: tdF('donation_date'),
    editable: 'never'
  },
  {
    field: 'donated_to',
    title: 'Donated To',
    position: 'sticky',
    top: 0,
    render: (row) => {
      const info = row.loan_info || [];
      let toDisplay = info;
      let moreText = '';
      if (info.length > 3) {
        toDisplay = info.slice(0, 2);
        moreText = ` ... + ${info.length - 2} more`;
      }
      return (
        <div>
          {toDisplay.map((el) => el.name).join(', ')}
          {moreText}
        </div>
      );
    },
    editable: 'never'
  }
];

export default React.memo(({ rows = [] }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [data, setData] = useState(rows);
  //   const [transactionData, setTransactionData] = useState([])
  //   const csvLink = useRef();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  useEffect(() => {
    setData(rows);
  }, [rows]);
  const cleanFormData = (newData) => {
    const params = {
      donation_amount: newData.donation_amount,
      donor_id: newData.donor_id,
      donor_type: newData.donor_type,
      email: newData.email,
      fname: newData.fname || '',
      lname: newData.lname || '',
      phone: newData.phone
    };
    return params;
  };
  const updateDonor = async (cleanedData) => {
    const res = await dispatch.dashboard.updateDonorInfo(cleanedData);
    if (res?.status === 200) {
      enqueueSnackbar('Update Successful', {
        autoHideDuration: 2000
      });
    } else {
      enqueueSnackbar('Failed to Update', {
        variant: 'error',
        autoHideDuration: 2000
      });
    }
    // window.location.reload();
  };
  const deleteDonor = async (oldData) => {
    if (!oldData.donor_id) {
      console.error('No donor Id when deleting');
    } else {
      const { donor_id } = oldData;
      const res = await dispatch.dashboard.deleteDonorInfo(donor_id);
      if (res?.status === 200) {
        enqueueSnackbar('Delete Successful', {
          autoHideDuration: 2000
        });
      } else {
        enqueueSnackbar('Failed to Delete', {
          variant: 'error',
          autoHideDuration: 2000
        });
      }
      // window.location.reload();
    }
  };
  const operation = (oldData, newData) => new Promise((resolve, reject) => {
      const dataUpdate = [...data];
      const index = oldData.tableData.id;
      if (newData) {
        const cleanedData = cleanFormData(newData);
        dataUpdate[index] = { ...newData };
        updateDonor(cleanedData);
      } else {
        dataUpdate.splice(index, 1);
        deleteDonor(oldData);
      }
      setData([...dataUpdate]);
      resolve();
    });

  return (
    <Grid className="tableMainDiv">
      {/* <Button variant="contained" onClick={exportAllfun}>Export All</Button> */}
      <CSVLink data={data}>Export All</CSVLink>
      <MaterialTable
        title="Donors"
        icons={TableIcons}
        columns={columns}
        data={data}
        options={{
          rowStyle: {
            fontFamily: theme.typography.fontFamily,
            ...theme.typography.body2
          },
          exportMenu: [
            {
              exportAllData: true,
              label: 'Export CSV',
              exportFunc: (cols, datas) => {
                const cleaned = datas.map((i) => i.map((j) => (typeof j === 'object' ? JSON.stringify(j) : j)));
                return ExportCsv(cols, cleaned, 'DonorInfoTable');
              }
            }
          ]
        }}
        editable={{
          onRowUpdate: (newData, oldData) => operation(oldData, newData),
          onRowDelete: (oldData) => operation(oldData)
        }}
        components={{
          EditRow: ({ onEditingApproved, ...props }) => {
            return MuiTableEditRow({ onEditingApproved, ...props }, FormSchema);
          },
          EditField: FormikMTInput,
          Action: (props) => {
            return <MTableAction {...props} size="small" />;
          }
        }}
      />
    </Grid>
  );
});
