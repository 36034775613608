import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
 add, format, isValid, parseISO 
} from 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import Page from 'src/components/Page';
import * as Api from 'src/Api';
import NumberFormat from 'react-number-format';

export const LocationSelect = ({ hideLabel, ...rest }) => (
  <TextField
    select
    fullWidth
    label={hideLabel ? '' : 'Location'}
    margin="normal"
    name="location"
    {...rest}
  >
    <MenuItem value="Rukungiri">Rukungiri</MenuItem>
    <MenuItem value="Mbarara">Mbarara</MenuItem>
  </TextField>
);

export const UGXInput = (props) => (
  <NumberFormat
    fullWidth
    customInput={TextField}
    margin="normal"
    thousandSeparator
    prefix="UGX  "
    {...props}
  />
);

export const UGXInputHidden = (props) => (
  <NumberFormat
    customInput={TextField}
    margin="normal"
    thousandSeparator
    prefix="UGX  "
    {...props}
  />
);

export const EndDateInput = (props) => {
  const startDate = props.values.start_date;
  const potentialDate = startDate instanceof Date ? startDate : parseISO(startDate);
  const displayDate = isValid(potentialDate)
    ? format(
        add(potentialDate, {
          weeks: props.values.term_length || 0
        }),
        'MM/dd/yyyy'
      )
    : '';
  return <TextField disabled fullWidth margin="normal" value={displayDate} />;
};

function roundUp(number, near) {
  if (number % near === 0) return number;
  return parseInt(number / near, 10) * near + near;
}

export const EstimatedLoan = (props) => {
  const { ...rest } = props;
  let loanAmount;
  if (props.values.bike_price === 0 || props.values.bike_price === '') {
    loanAmount = 0;
  } else {
    const bkpr1 = Number(props.values.bike_price);
    const dpro1 = Number(props.profit);
    const term1 = Number(props.values.term_length);
    const dpay1 = Number(props.values.down_payment);
    loanAmount = (bkpr1 + (dpro1 * term1 - dpay1)) / term1; // Math.round removed from here
    loanAmount = roundUp(loanAmount, 1000);
    // console.log(roundUp(157345.00, 1000));
  }

  return <NumberFormat customInput={TextField} {...rest} value={loanAmount} />;
};

export const LoanAmount = (props) => {
  const { ...rest } = props;
  const term1 = Number(props.values.term_length);
  const dpro = Number(props.values.desired_profit);
  const loanamt = Number(dpro * term1 + Number(props.values.bike_price));
  // console.log(loanamt);
  return <NumberFormat customInput={TextField} {...rest} value={loanamt} />;
};

export const USDInput = (props) => (
  <NumberFormat
    customInput={TextField}
    fullWidth
    margin="normal"
    thousandSeparator
    prefix="$  "
    {...props}
  />
);

export const GroupInput = ({ hideLabel, ...rest }) => (
  <TextField
    select
    fullWidth
    label={hideLabel ? '' : 'Fellowship Group'}
    margin="normal"
    name="fellowship_group"
    {...rest}
  >
    {[1, 2, 3, 4, 5, 6].map((el) => (
      <MenuItem key={el} value={`Group ${el}`}>
        {`Group ${el}`}
      </MenuItem>
    ))}
  </TextField>
);
