import deepmerge from 'deepmerge';
import * as Api from 'src/Api';
import { add, format, differenceInSeconds } from 'date-fns';
import isEmpty from 'src/utils/isEmpty';

// number of seconds before refetching
const TIME_BLOCK = 120;

const location = {
  state: { lastFetch: new Date(), data: {} }, // initial state
  reducers: {
    update(state, payload) {
      return { lastFetch: new Date(), data: payload };
    }
  },
  effects: (dispatch) => ({
    async fetchThenUpdate(payload, rootState) {
      const sinceLastSecs = differenceInSeconds(
        new Date(),
        rootState.location.lastFetch
      );

      if (isEmpty(rootState.location.data) || sinceLastSecs > TIME_BLOCK) {
        console.log(
          'Fetching Location Data. Time since last fetch',
          sinceLastSecs
        );
        const res = await Api.get('/location');

        if (res?.data?.data) {
          const temp = res.data.data;
          console.log(temp);
          const out = { location: temp };
          dispatch.location.update(out);
        }
      }
    },
    async updateLocation(payload, rootState) {
      return Api.put('/location', payload);
    },
    async deleteLocation(id, rootState) {
      return Api.del(`/location/${id}`);
    }
  })
};

export default location;
