import React, { useState, useEffect } from 'react';
import {
 Box, Typography, makeStyles, Grid 
} from '@material-ui/core';
import { Textfit } from 'react-textfit';
import { format } from 'date-fns';
import isEmpty from 'src/utils/isEmpty';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    borderLeft: '2px solid black',
    paddingLeft: theme.spacing(2)
  },
  b1: {
    ...theme.typography.body1
  }
}));

export default function LoanInfoText({
  curLoan: {
    fname = '',
    lname = '',
    bike_reg_no = '',
    start_date = '',
    term_length = 0,
    end_date = '',
    loan_amount = 0,
    loan_id
  },
  remaining = 0
}) {
  const classes = useStyles();
  // useEffect(() => {
  //   console.log(remaining);
  // }, [remaining]);

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h3">{`${fname} ${lname}`}</Typography>
          <Typography variant="body2" color="textSecondary">
            Bike: 
{' '}
<span className={classes.b1}>{bike_reg_no}</span>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Started on:
{' '}
            <span className={classes.b1}>
              {format(new Date(start_date), 'MM/dd/yyyy')}
            </span>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Due on:
{' '}
            <span className={classes.b1}>
              {format(new Date(end_date), 'MM/dd/yyyy')}
              {` (${term_length}`}
{' '}
week
{term_length === 1 || 's'}
)
            </span>
          </Typography>
        </Grid>
        <Grid container item xs={6} direction="row">
          <Grid
            item
            xs={12}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Box style={{ width: '100%' }} textAlign="center">
              <Typography variant="h2" className={classes.remaining}>
                <Textfit mode="single" max={60}>
                  <NumberFormat
                    thousandSeparator
                    value={isEmpty(remaining) ? 0 : remaining / 100}
                    displayType="text"
                  />
                </Textfit>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Box textAlign="center">
              <Typography
                variant="body2"
                display="inline"
                color="textSecondary"
              >
                Remaining of UGX
{' '}
                <NumberFormat
                  thousandSeparator
                  value={loan_amount / 100}
                  displayType="text"
                />
              </Typography>
            </Box>
            {/* <Typography>{`Of: ${curLoan?.loan_amount}`}</Typography> */}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
