import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Button
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Api from 'src/Api';
import Page from 'src/components/Page';
import isEmpty from 'src/utils/isEmpty';
import NumberFormat from 'react-number-format';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { Textfit } from 'react-textfit';
import InteractiveList from './donor-history';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  gutterRight: {
    marginRight: 15
  }
}));

const FormSchema = Yup.object().shape({
  donated_to: Yup.string().required('Required')
});
const DonorDetails = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [remaining, setremaining] = useState({});
  const [donorData, setDonorData] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();
  // const { enqueueSnackbar } = useSnackbar();
  const [firstname, setfName] = React.useState('');
  const [lastname, setlName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [donortype, setDonortype] = React.useState('');
  const [donationamt, setDonationamt] = React.useState('');
  const [donationdate, setDonationdate] = React.useState('');
  const [donorId, setdonorid] = React.useState('');
  const [opendropdown, setOpenDropdown] = React.useState(false);

  useEffect(() => {
    dispatch.form3.fetchThenUpdate();
    dispatch.form2.fetchThenUpdate();
  }, []);

  // const dashboardData = useSelector((state) => state.dashboard.data);
  // const rows = dashboardData || { loan: [], donor: [] };

  const form3Data = useSelector((state) => state.form3.data);
  const donors = isEmpty(form3Data) ? [] : form3Data;
  const selectedDonor = isEmpty(form3Data)
    ? []
    : donors.find((d) => {
        return d.donor_id === +id;
      });
  const [curDonor, setcurDonor] = useState(selectedDonor || {});
  // loan
  const form2Data = useSelector((state) => state.form2.data);
  const loans = isEmpty(form2Data) ? [] : form2Data;
  const selectedLoan = isEmpty(form2Data)
    ? []
    : loans.find((d) => {
        return d.loan_id === +id;
      });
  const [curLoan, setcurLoan] = useState(selectedLoan || {});

  async function fetchData() {
    const res = await Api.get(`/donor/${curDonor.donor_id}`);
    if (res?.data?.data.rows && !isEmpty(res.data.data.rows)) {
      setremaining(res.data.data.remaining_amount);
      setDonorData(res.data.data.rows[0].loan_info);
      setfName(res.data.data.rows[0].fname);
      setlName(res.data.data.rows[0].lname);
      setPhone(res.data.data.rows[0].phone);
      setEmail(res.data.data.rows[0].email);
      setDonortype(res.data.data.rows[0].donor_type);
      setDonationamt(res.data.data.rows[0].donation_amount);
      const datestore = format(
        new Date(res.data.data.rows[0].donation_date),
        'MM/dd/yyyy'
      );
      setDonationdate(datestore);
      setdonorid(res.data.data.rows[0].donor_id);
      console.log('loan', res.data.data.rows[0].loan_info);
    }
  }

  useEffect(() => {
    // async function fetchData() {
    //   const res = await Api.get(`/donor/${curDonor.donor_id}`);
    //   if (res?.data?.data.rows && !isEmpty(res.data.data.rows)) {
    //     setremaining(res.data.data.remaining_amount);
    //     setDonorData(res.data.data.rows[0].loan_info);
    //     setfName(res.data.data.rows[0].fname);
    //     setlName(res.data.data.rows[0].lname);
    //     setPhone(res.data.data.rows[0].phone);
    //     setEmail(res.data.data.rows[0].email);
    //     setDonortype(res.data.data.rows[0].donor_type);
    //     setDonationamt(res.data.data.rows[0].donation_amount);
    //     const datestore = format(
    //       new Date(res.data.data.rows[0].donation_date),
    //       'MM/dd/yyyy'
    //     );
    //     setDonationdate(datestore);
    //     setdonorid(res.data.data.rows[0].donor_id);
    //     console.log('loan', res.data.data.rows[0].loan_info);
    //   }
    // }

    if (curDonor.donor_id) fetchData();
    else if (selectedDonor) {
      setcurDonor(selectedDonor);
    }
  }, [curDonor, selectedDonor]);

  const handleExistingparent = () => {
    setOpenDropdown(true);
  };

  const cur = 'us';

  const numberFormatters = {
    us: new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }),
    ug: new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'UGX'
    })
  };

  const deleteDonateTo = async (rowId) => {
    const res = await dispatch.form3.deleteDonateTo(rowId);

    if (res?.status === 200) {
      enqueueSnackbar('Delete Successful', {
        autoHideDuration: 2000
      });
    } else {
      enqueueSnackbar('Failed to Delete', {
        variant: 'error',
        autoHideDuration: 2000
      });
    }
    fetchData();
  };

  const submitForm = async (values) => {
    const temp = values;
    const tempnew = { ...values, donor_id: Number.parseInt(donorId, 10) };
    console.log(tempnew);
    const res = await Api.post('/donor/addbike', {
      ...tempnew
    });
    if (res?.status === 200) {
      enqueueSnackbar('Submitted Successfully', {
        autoHideDuration: 2000
      });
      setOpenDropdown(false);
    } else {
      enqueueSnackbar('Failed to Submit', {
        variant: 'error',
        autoHideDuration: 2000
      });
    }
    console.log(res);
    fetchData();
  };
  return (
    <Page className={classes.root} title="Register">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Container maxWidth="sm">
          <>
            <Box>
              <Typography color="textPrimary" variant="h2">
                Donor Details
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Select Donor for more information
              </Typography>
            </Box>
            {!id && (
              <Autocomplete
                id="search"
                options={donors}
                getOptionLabel={(option) => `${option.fname} ${option.lname}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search For Donor"
                    name="donor_id"
                  />
                )}
                renderOption={(option) => (
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography>
                        {`${option.fname}  ${option.lname}`}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                onChange={(_, value) => {
                  setcurDonor(value);
                }}
              />
            )}
            {curDonor.fname && (
              <>
                <Box className={classes.root}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="h3">{`${firstname} ${lastname}`}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        phone: 
{' '}
<span className={classes.b1}>{phone}</span>
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Email: 
{' '}
<span className={classes.b1}>{email}</span>
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Donor Type:
{' '}
                        <span className={classes.b1}>{donortype}</span>
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Donation Amount: $
                        <span className={classes.b1}>
                          <NumberFormat
                            thousandSeparator
                            value={parseInt(parseFloat(donationamt) / 100, 10)}
                            displayType="text"
                          />
                        </span>
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Donation Date:
{' '}
                        <span className={classes.b1}>{donationdate}</span>
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Donation ID:
{' '}
                        <span className={classes.b1}>{donorId}</span>
                      </Typography>
                    </Grid>
                    <Grid container item xs={6} direction="row">
                      <Grid
                        item
                        xs
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                      >
                        <Box style={{ width: '100%' }} textAlign="center">
                          <Typography
                            variant="h2"
                            className={classes.remaining}
                          >
                            <Textfit mode="single" max={60}>
                              $
                              <NumberFormat
                                thousandSeparator
                                value={parseInt(
                                  parseFloat(donationamt) / 100,
                                  10
                                )}
                                displayType="text"
                              />
                            </Textfit>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Button
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={handleExistingparent}
                    >
                      Add New Donated to
                    </Button>
                  </Grid>
                </Box>
              </>
            )}
          </>
          <Box>
            <Formik
              initialValues={{
                fname: '',
                lname: '',
                phone: '',
                email: '',
                donor_type: '',
                donation_amount: '',
                donation_date: new Date().toISOString(),
                donated_to: []
              }}
              validationSchema={FormSchema}
              onSubmit={(values) => {
                submitForm(values, enqueueSnackbar);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                touched,
                values
              }) => {
                return (
                  <Form onSubmit={handleSubmit} noValidate>
                    {opendropdown && (
                      <Autocomplete
                        multiple
                        id="search"
                        options={loans}
                        getOptionLabel={(option) => `${option.fname}
					 ${option.lname} - ${option.bike_reg_no}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Donated To: "
                            name="donated_to"
                            error={Boolean(
                              touched.donated_to && errors.donated_to
                            )}
                            helperText={
                              touched.donated_to && errors.donated_to
                                ? errors.donated_to
                                : ''
                            }
                          />
                        )}
                        renderOption={(option) => (
                          // TODO: Make Name Float Left
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography>
                                {`${option.fname}  ${option.lname}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>{option.bike_reg_no}</Typography>
                            </Grid>
                          </Grid>
                        )}
                        onChange={(_, value) => {
                          setFieldValue('donated_to', value);
                          setcurLoan(value);
                        }}
                      />
                    )}
                    <Box my={2}>
                      {opendropdown && (
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      )}
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Container>
      </Box>
      {!isEmpty(donorData) && (
        <Grid container justify="center" style={{ marginTop: '20px' }}>
          <InteractiveList
            loadId={curDonor.donor_id}
            donorData={donorData}
            deleteDonateTo={deleteDonateTo}
          />
        </Grid>
      )}
    </Page>
  );
};

export default DonorDetails;
