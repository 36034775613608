import Search from '@material-ui/icons/Search';

import ViewColumn from '@material-ui/icons/ViewColumn';

import SaveAlt from '@material-ui/icons/SaveAlt';

import ChevronLeft from '@material-ui/icons/ChevronLeft';

import ChevronRight from '@material-ui/icons/ChevronRight';

import FirstPage from '@material-ui/icons/FirstPage';

import LastPage from '@material-ui/icons/LastPage';

import Add from '@material-ui/icons/Add';

import Check from '@material-ui/icons/Check';

import FilterList from '@material-ui/icons/FilterList';

import Remove from '@material-ui/icons/Remove';

import ArrowDownward from '@material-ui/icons/ArrowDownward';

import Clear from '@material-ui/icons/Clear';

import DeleteOutline from '@material-ui/icons/DeleteOutline';

import Edit from '@material-ui/icons/Edit';

export default {
  Check,
  Clear,
  DetailPanel: ChevronRight,
  Delete: DeleteOutline,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage,
  LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  Search,
  ThirdStateCheck: Remove,
  Add,
  SortArrow: ArrowDownward,
  ResetSearch: Clear,
  Edit,
  ViewColumn
};
