import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import '../../components/assets/Style.css';
import LocationInfoTable from './LocationInfoTable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LocationView = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch.location.fetchThenUpdate();
  }, []);

  const dashboardData = useSelector((state) => state.location.data);
  const rows = dashboardData || { location: [] };

  console.log(rows);

  return (
    <Page className={classes.root} title="Location">
      <Container maxWidth={false}>
        {/* <Button
          onClick={() => {
            dispatch.dashboard.fetchThenUpdate();
          }}
        >
          Reload
        </Button> */}
        <Box>
          <Typography color="textPrimary" variant="h2">
            Location Info
          </Typography>
        </Box>
        <Box mt={3}>
          <LocationInfoTable rows={rows.location} />
        </Box>
      </Container>
    </Page>
  );
};

export default LocationView;
