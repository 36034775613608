import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

export default function InteractiveList(props) {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">MANAGE</TableCell>
              <TableCell align="center">BIKE ID</TableCell>
              <TableCell align="center">DONATED TO</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.donorData.map((row) => (
              <TableRow key={row.donation_id}>
                <TableCell>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      // alert(row.donation_id);
                      props.deleteDonateTo(row.donation_id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">{row.bike_reg_no}</TableCell>
                <TableCell align="center">{row.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
