import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Api from 'src/Api';
import Page from 'src/components/Page';
import isEmpty from 'src/utils/isEmpty';
import LoanInfoText from 'src/views/forms/Form2/LoanInfoText';
import { useSnackbar } from 'notistack';
import LoanInfoTable from 'src/views/dashboard/LoanInfoTable';
import InteractiveList from './payment-history';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  gutterRight: {
    marginRight: 15
  }
}));

const LoanDetails = (props) => {
  const classes = useStyles();
  const [remaining, setremaining] = useState({});
  const [paymentData, setPaymentData] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  //   const [data, setData] = useState(rows);
  //   useEffect(() => {
  //     setData(rows);
  //   }, [rows]);

  useEffect(() => {
    dispatch.form2.fetchThenUpdate();
  }, []);
  const form2Data = useSelector((state) => state.form2.data);
  const loans = isEmpty(form2Data) ? [] : form2Data;
  const selectedLoan = isEmpty(form2Data)
    ? []
    : loans.find((d) => {
        return d.loan_id === +id;
      });
  const [curLoan, setcurLoan] = useState(selectedLoan || {});
  useEffect(() => {
    async function fetchData() {
      const res = await Api.get(`/loan/${curLoan.loan_id}/payments`);
      if (res?.data?.data && !isEmpty(res.data.data.remaining_amount)) {
        setremaining(res.data.data.remaining_amount);
        setPaymentData(res.data.data.payments);
      }
    }

    if (curLoan.loan_id) fetchData();
    else if (selectedLoan) {
      setcurLoan(selectedLoan);
    }
  }, [curLoan, selectedLoan]);

  const deletePayment = async (rowId) => {
    setPaymentData((old) => old.filter((el) => el.payment_id !== rowId));
    const res = await dispatch.form2.deletePayment(rowId);

    if (res?.status === 200) {
      enqueueSnackbar('Delete Successful', {
        autoHideDuration: 2000
      });
    } else {
      enqueueSnackbar('Failed to Delete', {
        variant: 'error',
        autoHideDuration: 2000
      });
    }
  };

  const cleanFormData = (newData) => {
    const name = newData.name.split(' ');
    const params = {
      loan_id: newData.loan_id,
      fname: name[0],
      phone: newData.phone,
      email: newData.email,
      lname: name[1]
    };

    return params;
  };
  //   const updatePayment = async (cleanedData) => {
  // alert('test');
  // const res = await dispatch.form2.updatePaymentInfo(cleanedData);
  // if (res?.status === 200) {
  //   enqueueSnackbar('Upadate Successful', {
  //     autoHideDuration: 2000
  //   });
  // } else {
  //   enqueueSnackbar('Failed to Update', {
  //     variant: 'error',
  //     autoHideDuration: 2000
  //   });
  // }
  // window.location.reload();
  //   };

  // const operation = (oldData, newData) => new Promise((resolve, reject) => {
  //     const dataUpdate = [...data];

  //     const index = oldData.tableData.id;
  //     if (newData) {
  //       const cleanedData = cleanFormData(newData);

  //       dataUpdate[index] = { ...newData };
  //       updatePayment(cleanedData);
  //     } else {
  //       dataUpdate.splice(index, 1);
  //       deletePayment(oldData);
  //     }
  //     setData([...dataUpdate]);
  //     resolve();
  //   });

  return (
    <Page className={classes.root} title="Register">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Container maxWidth="sm">
          <>
            <Box>
              <Typography color="textPrimary" variant="h2">
                Loan Details
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Select loan for more information
              </Typography>
            </Box>
            {!id && (
              <Autocomplete
                id="search"
                options={loans}
                getOptionLabel={(option) => `${option.fname} ${option.lname} - ${option.bike_reg_no}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search For Loan"
                    name="loan_id"
                  />
                )}
                renderOption={(option) => (
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography>
                        {`${option.fname}  ${option.lname}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{option.bike_reg_no}</Typography>
                    </Grid>
                  </Grid>
                )}
                onChange={(_, value) => {
                  setcurLoan(value);
                }}
              />
            )}
            {curLoan.fname && (
              <>
                <LoanInfoText remaining={remaining} curLoan={curLoan} />
                <Grid item xs={12}>
                  <Grid container justify="center" spacing={3}>
                    {curLoan.borrower_pict && (
                      <Grid item xs={6}>
                        <Card>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              src={curLoan.borrower_pict}
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                              >
                                Borrower Picture
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    )}
                    {curLoan.signature && (
                      <Grid item xs={6}>
                        <Card>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              src={curLoan.signature}
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                              >
                                Signature
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </>
        </Container>
      </Box>
      {!isEmpty(paymentData) && (
        <Grid container justify="center" style={{ marginTop: '20px' }}>
          <InteractiveList
            loadId={curLoan.loan_id}
            paymentData={paymentData}
            deletePayment={deletePayment}
            // updatePayment={updatePayment}
            // rows={paymentData}
            // data={paymentData}
            // columns={paymentData}
          />
          {/* <LoanInfoTable rows={paymentData} /> */}
        </Grid>
      )}
    </Page>
  );
};

export default LoanDetails;
