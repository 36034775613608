import React, { useEffect, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  Icon
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Lock as LockIcon,
  ShoppingBag as ShoppingBagIcon,
  DollarSign as MoneyIcon,
  Users as UsersIcon,
  Info as InfoIcon,
  MapPin as LocationIcon
} from 'react-feather';
import AuthContext from 'src/Context/AuthContext';
import { useSelector } from 'react-redux';
import NavItem from './NavItem';

const items = [
  {
    href: '/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/location',
    icon: LocationIcon,
    title: 'Location'
  },
  {
    href: '/LoanCalc',
    icon: MoneyIcon,
    title: 'Loan Calculator'
  },
  // {
  //   href: '/Form1',
  //   icon: UsersIcon,
  //   title: 'Form1'
  // },
  {
    href: '/Form2',
    icon: ShoppingBagIcon,
    title: 'Form2'
  },
  {
    href: '/Form3',
    icon: MoneyIcon,
    title: 'Form3'
  },
  {
    href: '/loanDetails',
    icon: InfoIcon,
    title: 'Loan Details'
  },
  {
    href: '/donorDetails',
    icon: InfoIcon,
    title: 'Donor Details'
  },
  {
    href: '/logout',
    icon: LockIcon,
    title: 'Logout'
  }
];

const permissions = {
  admin: [
    '/dashboard',
    '/location',
    '/LoanCalc',
    '/Form1',
    '/Form2',
    '/Form3',
    '/loanDetails',
    '/donorDetails',
    '/logout'
  ],
  user: ['/LoanCalc', '/Form1', '/Form2', '/loanDetails', '/logout']
};

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  const authStatus = useSelector((state) => state.auth.authStatus) || '';

  const sidebarItems = items
    .filter((el) => permissions[authStatus]?.includes(el?.href))
    .map((el) => {
      return { ...el, href: `/${authStatus}${el.href}` };
    });
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const user = {
    avatar: '/static/images/avatars/ambient.jpeg',
    jobTitle: 'Pureflow',
    name: authStatus
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to=""
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {sidebarItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box p={2} m={2} bgcolor="background.dark">
        <Typography align="center" gutterBottom variant="h6">
          Made with
{' '}
          <span role="img" aria-label="love">
            ❤️
          </span>
{' '}
          By Supra
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
