const numberFormatters = {
  us: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }),
  ug: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'UGX'
  })
};
// input: values from formik forms
export const convertToCents = (formValues) => {
  const out = { ...formValues };
  console.log(JSON.stringify(formValues));
  out.loan_amount = parseInt(parseFloat(out.loan_amount) * 100, 10);
  out.down_payment = parseInt(parseFloat(out.down_payment) * 100, 10);
  out.donation_amount = parseInt(parseFloat(out.donation_amount) * 100, 10);
  out.bike_price = parseInt(parseFloat(out.bike_price) * 100, 10);
  // out.desired_profit = parseInt(parseFloat(out.desired_profit) * 100, 10);
  console.log(out.desired_profit);
  let profit = 0;
  if ('desired_profit' in out) {
    if (out.desired_profit.toString().includes('%')) {
      const value = out.desired_profit.replace('%', '');
      profit = out.bike_price * (Number(value) / 100);
    } else {
      profit = parseInt(parseFloat(Number(out.desired_profit)) * 100, 10);
    }
    out.desired_profit = profit;
  }

  return out;
};
export default (num, cur = 'us') => {
  return numberFormatters[cur].format(parseFloat(num));
};
