import React from 'react';
import { MTableEditField, MTableEditRow } from '@material-table/core';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import { Field, Formik, getIn } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import NumberFormat from 'react-number-format';
import { convertToCents } from 'src/utils/formatCurrency';
import {
  GroupInput,
  LocationSelect,
  UGXInput,
  USDInput,
  EndDateInput
} from '../forms/FormComponents';

export const MuiTableEditRow = ({ onEditingApproved, ...props }, schema) => {
  const initialValues = { ...props.data };
  initialValues.loan_amount /= 100;
  initialValues.down_payment /= 100;
  initialValues.donation_amount /= 100;

  return (
    <Formik
      validationSchema={schema} // included a validation object
      initialValues={initialValues}
      onSubmit={(values) => {
        const cleanedValues = convertToCents(values);

        onEditingApproved(props.mode, cleanedValues, props.data);
      }}
    >
      {({ submitForm }) => (
        <MTableEditRow {...props} onEditingApproved={submitForm} />
      )}
    </Formik>
  );
};

export const FormikMTInput = (props) => (
  <Field name={props.columnDef.field}>
    {({ field, form }) => {
      const { name } = field;
      const {
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
        submitCount
      } = form;

      if (name === 'fellowship_group') {
        return (
          <GroupInput
            hideLabel
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.fellowship_group}
          />
        );
      }
      if (name === 'location') {
        return (
          <LocationSelect
            hideLabel
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.location}
          />
        );
      }
      if (name === 'end_date') {
        return (
          <EndDateInput label="End Date" name="end_date" values={values} />
        );
      }
      if (name === 'loan_amount') {
        return (
          <UGXInput
            name={String(name)}
            onBlur={handleBlur}
            error={Boolean(touched.loan_amount && errors.loan_amount)}
            helperText={
              touched.loan_amount && errors.loan_amount
                ? errors.loan_amount
                : ''
            }
            onValueChange={(valueObj) => {
              const { value } = valueObj;
              setFieldValue('loan_amount', value);
            }}
            value={values.loan_amount}
          />
        );
      }
      if (name === 'down_payment') {
        return (
          <UGXInput
            name={String(name)}
            onBlur={handleBlur}
            error={Boolean(touched.down_payment && errors.down_payment)}
            helperText={
              touched.down_payment && errors.down_payment
                ? errors.down_payment
                : ''
            }
            onValueChange={(valueObj) => {
              const { value } = valueObj;
              setFieldValue('down_payment', value);
            }}
            value={values.down_payment}
          />
        );
      }
      if (name === 'donation_amount') {
        return (
          <USDInput
            name={String(name)}
            onBlur={handleBlur}
            error={Boolean(touched.donation_amount && errors.donation_amount)}
            style={{ marginTop: '8px' }}
            helperText={
              touched.donation_amount && errors.donation_amount
                ? errors.donation_amount
                : ''
            }
            onValueChange={(valueObj) => {
              const { value } = valueObj;
              setFieldValue('donation_amount', value);
            }}
            value={values.donation_amount}
          />
        );
      }
      if (name === 'phone') {
        return (
          <NumberFormat
            customInput={TextField}
            fullWidth
            format="+1-(###)-###-####"
            style={{ marginTop: '-8px' }}
            mask="_"
            margin="normal"
            label="Phone Number"
            name="phone"
            onBlur={handleBlur}
            onValueChange={(valueObj) => {
              const { value } = valueObj;
              setFieldValue('phone', value);
            }}
            value={values.phone}
            thousandSeparator
            prefix="UGX  "
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone ? errors.phone : ''}
          />
        );
      }
      if (name === 'donor_type') {
        return (
          <TextField
            label="Donor Type"
            id="donor_type"
            name="donor_type"
            margin="normal"
            fullWidth
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.donor_type}
            style={{ marginTop: '-8px' }}
            select
            error={Boolean(touched.donor_type && errors.donor_type)}
            helperText={
              touched.donor_type && errors.donor_type ? errors.donor_type : ''
            }
          >
            <MenuItem value="Donor">Donor</MenuItem>
            <MenuItem value="Investor">Investor</MenuItem>
          </TextField>
        );
      }

      return (
        <div>
          <TextField
            error={Boolean(touched[name] && errors[name])}
            fullWidth
            helperText={touched[name] && errors[name] ? errors[name] : ''}
            name={String(name)}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values[name]}
          />
        </div>
      );
    }}
  </Field>
);
