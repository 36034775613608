import React, { useContext } from 'react';
import { Container, makeStyles, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import AuthContext from 'src/Context/AuthContext';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const { authStatus, changeAuthStatus } = useContext(AuthContext);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Typography color="textSecondary" variant="h6">
          Dashboard Tables 
{' '}
{authStatus}
        </Typography>
      </Container>
    </Page>
  );
};

export default Dashboard;
