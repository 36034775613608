import React from 'react';
import formatCurrency from 'src/utils/formatCurrency';
import { parseISO, format } from 'date-fns';
import { Link } from 'react-router-dom';

export const ugF = (col) => (row) => formatCurrency((row[col] || 0) / 100, 'ug');
export const usF = (col) => (row) => formatCurrency((row[col] || 0) / 100, 'us');
export const tdF = (col) => (row) => format(parseISO(row[col] || new Date()), 'MM/dd/yyyy');
export const liF = (col) => (row) => {
  return <Link to={`/admin/loanDetails/${row.loan_id}`}>{row[col]}</Link>;
};
export const diF = (col) => (row) => {
  return <Link to={`/admin/donorDetails/${row.donor_id}`}>{row[col]}</Link>;
};
