import deepmerge from 'deepmerge';
import * as Api from 'src/Api';
import { add, format, differenceInSeconds } from 'date-fns';
import isEmpty from 'src/utils/isEmpty';

// number of seconds before refetching
const TIME_BLOCK = 120;

const dashboard = {
  state: { lastFetch: new Date(), data: {} }, // initial state
  reducers: {
    update(state, payload) {
      return { lastFetch: new Date(), data: payload };
    }
  },
  effects: (dispatch) => ({
    async fetchThenUpdate(payload, rootState) {
      const sinceLastSecs = differenceInSeconds(
        new Date(),
        rootState.dashboard.lastFetch
      );

      if (isEmpty(rootState.dashboard.data) || sinceLastSecs > TIME_BLOCK) {
        console.log(
          'Fetching Dashhboard Data. Time since last fetch',
          sinceLastSecs
        );
        const res = await Api.get('/dashboard/bothInfo');
        console.log(res);
        if (res?.data?.data?.loan?.rows && res?.data?.data?.donor?.rows) {
          const temp = res.data.data;
          const out = { loan: temp.loan.rows, donor: temp.donor.rows };
          dispatch.dashboard.update(out);
        }
      }
    },
    async updateLoanInfo(payload, rootState) {
      return Api.put('/loan/', payload);
    },
    async deleteLoanInfo(id, rootState) {
      return Api.del(`/loan/${id}`);
    },
    async updateDonorInfo(payload, rootState) {
      return Api.put('/donor', payload);
    },
    async deleteDonorInfo(id, rootState) {
      return Api.del(`/donor/${id}`);
    }
  })
};

export default dashboard;
