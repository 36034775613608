import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/dashboard';
import DashboardView2 from 'src/views/reports/DashboardView/index2';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import Form1View from 'src/views/forms/Form1';
import Form2View from 'src/views/forms/Form2';
import Form3View from 'src/views/forms/Form3';
import LocationView from 'src/views/forms/FormLocation';
import LoanCalculatorView from 'src/views/forms/LoanCalculator';
import LogoutView from 'src/views/auth/LogoutView';
import LoanDetails from 'src/views/loan/index';
import DonorDetails from 'src/views/donor/index';
import Paymentedit from 'src/views/loan/payment-edit';
import LocationDetails from 'src/views/location/index';

const routes = [
  {
    path: 'admin',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'dashboard2', element: <DashboardView2 /> },
      { path: 'form1', element: <Form1View /> },
      { path: 'form2', element: <Form2View /> },
      { path: 'form3', element: <Form3View /> },
      { path: 'loancalc', element: <LoanCalculatorView /> },
      { path: 'loanDetails/:id', element: <LoanDetails /> },
      { path: 'loanDetails', element: <LoanDetails /> },
      { path: 'location', element: <LocationDetails /> },
      { path: 'addlocation', element: <LocationView /> },
      { path: 'donorDetails/:id', element: <DonorDetails /> },
      { path: 'donorDetails', element: <DonorDetails /> },
      { path: 'payment-edit/:id', element: <Paymentedit /> },
      { path: 'logout', element: <LogoutView /> },
      { path: '/', element: <Navigate to="/admin/dashboard" /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/app/404" replace /> }
    ]
  },
  {
    path: 'user',
    element: <DashboardLayout />,
    children: [
      { path: 'loancalc', element: <LoanCalculatorView /> },
      { path: 'form1', element: <Form1View /> },
      { path: 'form2', element: <Form2View /> },
      { path: 'loanDetails/:id', element: <LoanDetails /> },
      { path: 'loanDetails', element: <LoanDetails /> },
      { path: 'donorDetails/:id', element: <DonorDetails /> },
      { path: 'donorDetails', element: <DonorDetails /> },
      { path: 'payment-edit/:id', element: <Paymentedit /> },
      { path: 'logout', element: <LogoutView /> },
      { path: '/', element: <Navigate to="/user/loancalc" /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/user/404" replace /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" replace /> }
    ]
  }
];

export default routes;
