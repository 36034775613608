import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import MaterialTable, { MTableAction } from '@material-table/core';
import { ExportCsv } from '@material-table/exporters';
import TableIcons from 'src/utils/TableIcons';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
  FormHelperText
} from '@material-ui/core';

export default React.memo(({ rows = [], ...rest }) => {
  const history = useNavigate();
  const theme = useTheme();
  const [data, setData] = useState(rows);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  useEffect(() => {
    setData(rows);
  }, [rows]);

  const rowsList = rows.length > 0
    && rows.map((item, i) => {
      return (
        <MenuItem value={item.loc_id} key={item.loc_id}>
          {item.loc_name}
        </MenuItem>
      );
    }, this);

  return (
    <TextField
      select
      fullWidth
      label="Location"
      margin="normal"
      name="location"
      {...rest}
    >
      {/* <MenuItem value="0">Select Location</MenuItem> */}
      {rowsList}
    </TextField>
  );
});
