import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { add, format, isValid } from 'date-fns';
import isEmpty from 'src/utils/isEmpty';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import LocationSelect from 'src/views/forms/LocationSelect';
import * as Api from 'src/Api';
import NumberFormat from 'react-number-format';
import SignatureCanvas from 'react-signature-canvas';
import { useSnackbar } from 'notistack';

import { UGXInput, UGXInputHidden, EstimatedLoan } from './FormComponents';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const FormSchema = Yup.object().shape({
  // loc_name: Yup.string().required('Required'),
  bike_price: Yup.number().required('Required'),
  term_length: Yup.number()
    .min(1, 'Should be greater than or equal to 1')
    .required('Required'),
  down_payment: Yup.number().required('Required')
});

const LoanCalculatorView = () => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch.location.fetchThenUpdate();
  }, []);

  // // new code yamini
  const [bike_price, setBikePrice] = React.useState('');
  const [desired_profit, setDesiredProfit] = React.useState('');
  const [curLoc, setCurLoc] = useState({});
  const [locname, setLocName] = useState({});

  function roundUp(number, near) {
    if (number % near === 0) return number;
    return parseInt(number / near, 10) * near + near;
  }

  const handleLocChange = (event) => {
    setCurLoc(event.target.value);
    async function fetchData() {
      const res = await Api.get(`/location/${event.target.value}`);
      console.log(res);
      if (res?.data?.data && !isEmpty(res.data.data)) {
        setBikePrice(res.data.data.bike_price / 100);
        setDesiredProfit(res.data.data.desired_profit / 100);
        setLocName(res.data.data.loc_name);
      }
    }
    if (event.target.value !== '') fetchData();
  };

  const submitForm = async (values, navigate, enqueueSnackbar) => {
    const temp = { ...values, loc_id: curLoc, desiredpro: desired_profit };
    const bkpr1 = Number(temp.bike_price);
    const dpro1 = Number(desired_profit);
    const term1 = Number(temp.term_length);
    const dpay1 = Number(temp.down_payment);
    let weeklypay = (bkpr1 + (dpro1 * term1 - dpay1)) / term1;
    weeklypay = roundUp(weeklypay, 1000);
    console.log(bkpr1, dpro1, term1, dpay1, weeklypay);
    temp.estimated_weekly_payment = weeklypay;
    temp.loc_name = locname;
    history('/user/form1', { state: temp }, { replace: true });
  };

  // code end

  const dashboardData = useSelector((state) => state.location.data);
  const rows = dashboardData || { location: [] };

  const { enqueueSnackbar } = useSnackbar();
  return (
    <Page className={classes.root} title="Register">
      <Box
        display="flex"
        flexDirection="column"
        // height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            validationSchema={FormSchema}
            initialValues={{
              loc_name: '',
              bike_price: 0,
              term_length: 0,
              down_payment: 100,
              estimated_weekly_payment: 0
            }}
            onSubmit={(values) => submitForm(values, enqueueSnackbar)}
          >
            {({
              errors,
              handleBlur,
              handleSubmit,
              handleChange,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              submitCount
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Box>
                  <Typography color="textPrimary" variant="h2">
                    Loan Calculator
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Enter Information For Loan Calculator
                  </Typography>
                </Box>

                <LocationSelect
                  name="loc_name"
                  rows={rows.location}
                  onBlur={handleBlur}
                  onChange={handleLocChange}
                  error={Boolean(touched.loc_name && errors.loc_name)}
                  helperText={
                    touched.loc_name && errors.loc_name ? errors.loc_name : ''
                  }
                  // onValueChange={(valueObj) => {
                  //     const { value } = valueObj;
                  //     setFieldValue('loc_name', value);
                  // }}
                  value={curLoc}
                />

                <UGXInputHidden
                  // label="Bike Price"
                  name="bike_price"
                  onBlur={handleBlur}
                  error={Boolean(touched.bike_price && errors.bike_price)}
                  helperText={
                    touched.bike_price && errors.bike_price
                      ? errors.bike_price
                      : ''
                  }
                  onValueChange={(valueObj) => {
                    const { value } = valueObj;
                    setFieldValue('bike_price', value);
                  }}
                  value={bike_price}
                  // displayType="text"
                  type="hidden"
                />

                <UGXInput
                  label="Down Payment"
                  name="down_payment"
                  onBlur={handleBlur}
                  onValueChange={(valueObj) => {
                    const { value } = valueObj;
                    setFieldValue('down_payment', value);
                  }}
                  value={values.down_payment}
                  thousandSeparator
                  prefix="UGX  "
                  error={Boolean(touched.down_payment && errors.down_payment)}
                  helperText={
                    touched.down_payment && errors.down_payment
                      ? errors.down_payment
                      : ''
                  }
                />

                <TextField
                  fullWidth
                  label="Term Length (weeks)"
                  error={Boolean(touched.term_length && errors.term_length)}
                  helperText={
                    touched.term_length && errors.term_length
                      ? errors.term_length
                      : ''
                  }
                  min={0}
                  type="number"
                  margin="normal"
                  name="term_length"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.term_length}
                />

                <EstimatedLoan
                  label="Estimated Weekly Payment"
                  name="estimated_weekly_payment"
                  values={values}
                  profit={desired_profit}
                  fullWidth
                  margin="normal"
                  thousandSeparator
                  prefix="UGX  "
                />

                <Box my={2}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Convert to Loan
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoanCalculatorView;
