import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  Chip,
  MenuItem,
  Grid
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Page from 'src/components/Page';
import SlideToggle from 'src/utils/SlideToggle';
import * as Api from 'src/Api';
import isEmpty from 'src/utils/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { useSnackbar } from 'notistack';
import LoanInfoText from './LoanInfoText';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  gutterRight: {
    marginRight: 15
  }
}));

const submitForm = async (values, enqueueSnackbar, navigate) => {
  const temp = { ...values };
  temp.payment_amount = parseInt(parseFloat(temp.payment_amount) * 100, 10);
  console.log(temp);
  const res = await Api.post('/payment', {
    ...temp
  });
  if (res?.status === 200) {
    enqueueSnackbar('Payment Successful', {
      autoHideDuration: 2000
    });
    navigate(`/admin/loanDetails/${res.data.data.loan_id}`, {
      replace: true
    });
  } else {
    enqueueSnackbar('Payment Failed', {
      variant: 'error',
      autoHideDuration: 2000
    });
  }
  console.log(res);
};

const RegisterView = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const formSchema = Yup.object().shape({
    loan_id: Yup.string().required('Required'),
    payment_type: Yup.string().required('Required'),
    payment_amount: Yup.string().required('Required'),
    transaction_id: Yup.string().when('payment_type', (payment_type, yup) => {
      return payment_type && payment_type === 'Mobile'
        ? yup.required('Required')
        : yup;
    })
  });

  const [curLoan, setcurLoan] = useState({});
  const [remaining, setremaining] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch.form2.fetchThenUpdate();
  }, []);

  const form2Data = useSelector((state) => state.form2.data);
  const loans = isEmpty(form2Data) ? [] : form2Data;

  // get remaining amount after new loan is selected.
  useEffect(() => {
    async function fetchData() {
      const res = await Api.get(`/loan/${curLoan.loan_id}/payments`);
      if (res?.data?.data && !isEmpty(res.data.data.remaining_amount)) {
        console.log(res.data);
        setremaining(res.data.data.remaining_amount);
      }
    }
    if (curLoan.loan_id) fetchData();
  }, [curLoan]);

  function roundUp(number, near) {
    if (number % near === 0) return number;
    return parseInt(number / near, 10) * near + near;
  }

  return (
    <Page className={classes.root} title="Register">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              loan_id: '',
              payment_type: 'Cash',
              transaction_id: '',
              payment_amount: ''
            }}
            validationSchema={formSchema}
            onSubmit={(values) => submitForm(values, enqueueSnackbar, navigate)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              touched,
              values
            }) => {
              const chipClick = (which) => {
                const curRem = isEmpty(remaining) ? 0 : remaining;
                if (which === 'full') {
                  setFieldValue('payment_amount', roundUp(curRem / 100), 1000); // roundUp(weeklypay, 1000)
                } else if (which === 'week') {
                  setFieldValue(
                    'payment_amount',
                    roundUp(
                      (curRem / 100 / curLoan.term_length).toFixed(2),
                      1000
                    )
                  );
                }
              };

              return (
                <Form onSubmit={handleSubmit} noValidate>
                  <Box>
                    <Typography color="textPrimary" variant="h2">
                      Collect Payments
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Enter loan payment information
                    </Typography>
                  </Box>
                  {curLoan.fname && (
                    <LoanInfoText remaining={remaining} curLoan={curLoan} />
                  )}

                  <Autocomplete
                    id="search"
                    options={loans}
                    getOptionLabel={(option) => `${option.fname} ${option.lname} - ${option.bike_reg_no}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search For Loan"
                        name="loan_id"
                        error={Boolean(touched.loan_id && errors.loan_id)}
                        helperText={
                          touched.loan_id && errors.loan_id
                            ? errors.loan_id
                            : ''
                        }
                      />
                    )}
                    renderOption={(option) => (
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography>
                            {`${option.fname}  ${option.lname}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{option.bike_reg_no}</Typography>
                        </Grid>
                      </Grid>
                    )}
                    onChange={(_, value) => {
                      setFieldValue('loan_id', value?.loan_id);
                      setcurLoan(value);
                    }}
                  />

                  <Grid container alignItems="baseline">
                    <NumberFormat
                      customInput={TextField}
                      error={Boolean(
                        touched.payment_amount && errors.payment_amount
                      )}
                      helperText={
                        touched.payment_amount && errors.payment_amount
                          ? errors.payment_amount
                          : ''
                      }
                      margin="normal"
                      label="Payment Amount"
                      name="payment_amount"
                      id="payment_amount"
                      onBlur={handleBlur}
                      onValueChange={(valueObj) => {
                        const { value } = valueObj;
                        setFieldValue('payment_amount', value);
                      }}
                      value={values.payment_amount}
                      className={classes.gutterRight}
                      thousandSeparator
                      prefix="UGX  "
                    />
                    <Chip
                      label="Weekly Installment"
                      onClick={() => {
                        chipClick('week');
                      }}
                      className={classes.gutterRight}
                    />
                    <Chip
                      label="Full Amount"
                      onClick={() => {
                        chipClick('full');
                      }}
                    />
                  </Grid>
                  <TextField
                    label="Payment Type"
                    id="payment_type"
                    name="payment_type"
                    margin="normal"
                    fullWidth
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.payment_type}
                  >
                    <MenuItem value="Cash">Cash</MenuItem>
                    <MenuItem value="Mobile">Mobile</MenuItem>
                  </TextField>
                  <SlideToggle open={values.payment_type === 'Mobile'}>
                    <TextField
                      fullWidth
                      label="Transaction ID"
                      name="transaction_id"
                      margin="normal"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.transaction_id}
                      helperText={
                        touched.transaction_id && errors.transaction_id
                          ? 'Required'
                          : ''
                      }
                      error={Boolean(
                        touched.transaction_id && errors.transaction_id
                      )}
                    />
                  </SlideToggle>
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default RegisterView;
